<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <BaseDatatable
          :key="componentKey"
          :tableData="listData"
          :model="dataModel"
          :options="options"
          :filterDefault="filters"
          :getFilterOptions="getFilterOptions"
          :actions="getRowActions()"
          v-on:action-click="onActionClicked"
          @onDataChange = 'DataOnChange'
        ></BaseDatatable>
        <Dialog
          style=""
          header="Delete"
          :visible.sync="showDeleteDialog"
          :contentStyle="{overflow: 'visible'}"
          :modal="true"
        >
          Are you sure you want to delete <strong>{{this.deleteName}}</strong> ?
          <template
            #footer
          >
            <Button label="Delete" @click="deleteData()" icon="pi pi-check" class="p-button-danger" />
            <Button
              label="Cancel"
              @click="showDeleteDialog = false"
              icon="pi pi-times"
              class="p-button-warning callback-btn"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import DataServices from '@/core/DataServices'
import BaseDatatable from './list'
import gql from "graphql-tag";
export default {
  components: {
    BaseDatatable,
  },
  data() {
    return {
      filters : {},
      filterOptions: {},
      options: [],
      listData: [],
      modelName: '',
      dataModel: null,
      title: 'Active user',
      componentKey: 0,
      page_transaction:null,
      editId: null,
      displayDialog: false,
      editDialogKey: 0,
      dialogMode: 'edit',
      deleteId: null,
      deleteName: null,
      showDeleteDialog: false,
      dataDelete :{},
      role: {
        view: false,
        add: false,
        edit: false,
        delete: false,
      },
      showRejectDialog : false,
      dataReject : null,
      reason : '',
      dataUsers : null,
    }
  },
  mounted () {
    this.initParams();
  },
  async created() {
    this.modelName = this.$route.meta.model;
    this.dataModel = DataServices.getModel(this.modelName);
    //console.log('this.dataModel:',this.dataModel);
    this.title = this.dataModel.name
    //lấy tên
    if (this.$route.params.id !== undefined) await this.getData(this.$route.params.id);
    
    this.$store.commit('setLoading', true);
    if(this.$route.params.reload) {
      window.location.reload();
    }
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_CLUBS);
    if (arr_role.indexOf(this.$constants.ROLE.VIEW) < 0){
      this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
    }
    this.role.add = (arr_role.indexOf(this.$constants.ROLE.ADD) < 0) ? false:true;
    this.role.edit = (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0) ? false:true;
    this.role.delete = (arr_role.indexOf(this.$constants.ROLE.DELETE) < 0) ? false:true;
  },
  watch: {
    '$route': 'initParams'
  },
  methods: {
    initParams() {
      this.$store.commit('setLoading', true);
      // this.modelName = this.$route.meta.model;
      // this.dataModel = DataServices.getModel(this.modelName);
      // this.title = this.dataModel.label;
      this.callbackOptions();
    },
    onChange() {
      this.$store.commit('setLoading', true);
      this.$apollo.queries.listData.refresh()
    },
    DataOnChange(data,f){
      if(f.type == 'Checkbox'){
        let dataUpdate = {}
        if(f.key == 'active'){
          dataUpdate['active'] = data[f.key]
        }
        let variables = {
          'data_update': dataUpdate,
          'where_key': {
            'id': {'_eq': data.id}
          }
        };
        this.$apollo.mutate({
          mutation: DataServices.updateData(this.modelName),
          variables: variables
        }).then(() => {})
      }
    },
    getFilterOptions(name) {
      var field = this.dataModel.getField(name)
      // console.log("field",field)
      var result = []
      if (field.type == 'Selection') {
        if (field.options ) result = field.options;
        else if(field.models && this.options[name]) result = this.options[name];
      } else {
        result = this.filterOptions[name] != null ? [...this.filterOptions[name]] : []
      }
      // console.log("result",result)
      return result;
    },
    async getData(id) {
      // console.log("dasdasd",id);
      var {data} = await this.$apollo.query({
        query: DataServices.getList('clubs', {
          "fields" : `id name_en`}),
        variables: {
          where_key: {
            id: {'_eq': id}
          }
        },
        fetchPolicy: "network-only"
      })
      var model = data[Object.keys(data)];
      // this.dataUsers = model[0]
      this.title = 'Active user - '+model[0].name_en;
    },
    getRowActions() {
      var actions = {}
      var that = this;
      // if (this.role.add) {
      //   actions.header = {name: 'add', icon: 'plus', path: `list-clubs/add`};
      // }
      // actions.header = { name: 'add', icon: 'plus'};
      actions.rows = function (rowData) {
        var buttons = {}
        //buttons.view = { name: 'view', icon: 'eye', key: 'id', path: `list-${that.modelName}/view/`};
        if (that.role.edit) {
          // if(rowData.users){
          //   if(rowData.users.status == 1 || rowData.users.status == '1'){
          //     buttons.rejected = {name: 'rejected', icon: 'times', key: 'id'};
          //     buttons.approved = {name: 'approved', icon: 'check', key: 'id'};
          //   }
          // }
          buttons.edit = {name: 'edit', icon: 'pencil', key: 'id', path: `/admin/list-users/edit/`};
        }
        // if (that.role.delete) {
        //   buttons.delete = {name: 'delete', icon: 'trash', key: 'id'};
        // }
        if (that.dataModel.actions) {
          var modelaction = that.dataModel.actions(rowData)
          Object.assign(buttons, modelaction);
        }
        return buttons
      }
      return actions
    },
    async callbackOptions() {
      var fields = this.dataModel.getDisplayFields();
      var attributes = [];
      for (let index = 0; index < fields.length; index++) {
        const element = fields[index];
        if (element.models !== undefined && element.models !== null) {
          // console.log("SDFGHJK",element)
          var optionsData = [];
          attributes.push(element.models.key.key);
          attributes.push(element.models.display_key);
          var where_ = {}
          if( element['name']== "name_sports"){
            // console.log("element",element)
            let {data} = await this.$apollo.query({
              query: DataServices.getList("sports", {"fields" : "id sports_lang{lang name}"}),
              variables: {
                where_key : {
                  deleted : {_eq: false}
                },
                orderBy:[{ordinal: 'desc'},{created_at : 'desc'}]
              }
            })
            if (data) {
              var ress = data[Object.keys(data)];
              // console.log("+=============",ress)
              if (ress) {
                for (let index = 0; index < ress.length; index++) {
                  const ele = ress[index];
                  var name_sports = ''
                  if(ele.sports_lang){
                    for (let j = 0; j < ele.sports_lang.length; j++) {
                      if(ele.sports_lang[j].lang == 'en'){
                        name_sports = ele.sports_lang[j].name;
                        break;
                      }
                    }
                  }
                  if(name_sports !== ''){
                    optionsData.push({value: ele.id, label: name_sports})
                  }
                }
              }
            }
          }else{
            let {data} = await this.$apollo.query({
              query: DataServices.getList(element.models.tableName, {"fields" : attributes.join(',')}),
              variables: {where_key : where_}
            })
            if (data) {
              var result = data[Object.keys(data)];
              if (result) {
                for (let index = 0; index < result.length; index++) {
                  const ele = result[index];
                  optionsData.push({value: ele[attributes[0]], label: ele[attributes[1]]})
                }
              }
            }
          }
          // console.log("optionsData",optionsData)
          this.options[element.key] = optionsData;
        }
        this.componentKey++;
      }
    },
    async onActionClicked(payload) {
      var action = payload.action
      var data = null
      if (payload.data) {
        data = payload.data
      }
      var pageTransaction = (new Date()).getTime();
      switch (action.name) {
        case 'view':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path + data.data[action.key] })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'edit':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.$router.push({ path: action.path + data.data[action.key],query: { page_transaction: pageTransaction.toString() }  })
          } else  {
            this.page_transaction = pageTransaction.toString();
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'delete':
          this.deleteId = data.data[action.key]
          this.deleteName = data.data.name_en
          this.dataDelete = data.data
          this.showDeleteDialog = true
          break
        case 'add':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path })
          } else  {
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'approved':
          await this.UpdateStatus('approved',data.data);
          // this.componentKey++
          break
        case 'rejected':
          // console.log("data.data",data.data)
          this.showRejectDialog = true;
          this.dataReject = data.data
          // this.componentKey++
          break
        case 'custom':
          await action.handler(data.data, this)
          this.componentKey++
          break
      }
    },
    async CancelReject(){
      this.showRejectDialog = false;
      this.reason = '';
    },
    async RejectClub(){
      // console.log("this.dataReject",this.dataReject)
      // console.log("this.reason",this.reason)
      await this.UpdateStatus('reject',this.dataReject,this.reason)
      this.showRejectDialog = false;
      this.reason = '';
    },
    async UpdateStatus(key,data,reason=''){
      console.log("datadatadata",data)
      console.log("key",key)
      var uid = data.users.id
      if(uid){
        var query = DataServices.updateData('users')
        var variables = null;
        if(key == 'approved'){
          variables = {
            'data_update': {
              'status': 2
            },
            where_key: {
              id: {'_eq': uid}
            }
          }
          
        }else if(key == 'reject'){
          variables = {
            'data_update': {
              'status': 4,
            },
            where_key: {
              id: {'_eq': uid}
            }
          }
        }
        if(variables){
          var that = this;
          await this.$apollo.mutate({
            mutation: query,
            variables: variables
          }).then(async () => {
            if(key == "approved"){
              //xóa bảng lý do nếu có
              var query_del = DataServices.deleteData('reason')
              await that.$apollo.mutate({
                mutation: query_del,
                variables: {where_key: {uid : { "_eq" :uid}}}
              })
            }else if(key == 'reject'){
              var reason_objects = {
                uid : uid,
                text : reason,
              }
              await that.$apollo.mutate({
                mutation: DataServices.insertData('reason'),
                variables: {
                  objects: reason_objects
                }
              })
            }
            this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Update status successfully', life: 3000 });
            this.componentKey++;
          })
        }
      }
      // if(key=='approved'){

      // }
    },
    async sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    async deleteData() {
      console.log("testttttttttt");
      this.$commonFuction.is_loading(true);
      var resDataAccount = await this.$CoreService.getAccountByUid(this.deleteId);
      this.showDeleteDialog = false;
      if (resDataAccount && resDataAccount.length > 0){
        //res[0].id;
        // var account_id = resDataAccount[0].id;
        // var phone = this.$commonFuction.convertPhone(resDataAccount[0].account);
        var uid = this.deleteId;
        var that = this;
        //Xóa account cũ
        // id mới dc thêm vào
        var userUpdate = {
          'deleted': true,
        };
        var accountUpdate = {
          'deleted': true,
        }
        var queryUpdate = `
          mutation update_users_multil($users_object: users_set_input,$accounts_object: accounts_set_input,$group_user_object: group_user_set_input) {
            update_users(where: { id: {_eq: "${uid}"}}, _set: $users_object) {
              affected_rows
              returning {
                id
              }
            }
            update_accounts(where: { uid: {_eq: "${uid}"}}, _set: $accounts_object) {
              affected_rows
              returning {
                id
              }
            }
          }
        `;

        let variables = {
          users_object: userUpdate,
          accounts_object: accountUpdate,
        };
        that.$apollo.mutate({
          mutation: gql(queryUpdate),
          variables: variables
        }).then(async (res) => {
          console.log(res);
          that.$commonFuction.is_loading(false);
          that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Delete data successfully', life: 3000 })
          //that.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Lưu dữ liệu thành công', life: 3000 })
          // window.location.reload()
          that.componentKey ++;
        });
      }
    },
    
  },
  

}
</script>
<style lang="css">
.p-calendar .p-datepicker { width: 280px; padding: 0px; padding-top: 5px;}
.p-calendar .p-datepicker td, .p-calendar .p-datepicker td a {padding: 0px !important;}
.p-calendar .p-datepicker td span {padding: 0px !important; width: 30px !important; height: 30px !important;}
.p-datepicker .p-datepicker-header{padding: 5px !important;}
.p-button-warning-o .p-button-label-o{
  color: #e95d57;
}
.p-button-warning .pi-times{
  color: #e95d57;
}
</style>